import { isModelPlacementWall } from './_uiHelpers'

export function lighting(productId, currentParams) {
	const lightColor = 0xddddff
	const lightIntensity = 0.65
	const lightDist = 100 // a long way away to swamp the effect of model size differences (there is no falloff in a directional light)

	let groundPlanePosition = [0, 0, 0]
	let rotationX = Math.PI * -0.5
	let shadowLightPosition = [0, 1 * lightDist, 0]
	let shadowMapSize = 256
	let shadowOpacity = 0.2
	// different shadow setup for 'wall' placement vs. 'floor'
	if (isModelPlacementWall(productId) && currentParams !== undefined) {
		groundPlanePosition = [0, 0, -currentParams['WidthMax'] / 100 - 0.005] // .005 to avoid dimension flickering
		rotationX = Math.PI * 0
		shadowLightPosition = [0, 0.5 * lightDist, 1 * lightDist]
		shadowMapSize = 512 // shadows a little sharper for shelf
		shadowOpacity = 0.15 // a little lighter
	}

	return (
		<>
			{/* scene lighting, 4 lights in the room high corners */}
			<ambientLight color={0xffffff} intensity={0.6} />
			<directionalLight position={[2 * lightDist, 1 * lightDist, 2 * lightDist]} color={lightColor} intensity={lightIntensity} />
			<directionalLight position={[2 * lightDist, 1 * lightDist, -2 * lightDist]} color={lightColor} intensity={lightIntensity} />
			<directionalLight position={[-2 * lightDist, 1 * lightDist, 2 * lightDist]} color={lightColor} intensity={lightIntensity} />
			<directionalLight position={[-2 * lightDist, 1 * lightDist, -2 * lightDist]} color={lightColor} intensity={lightIntensity} />
			{/* a subtle front/left light */}
			<directionalLight position={[-1 * lightDist, 0, 0.5 * lightDist]} color={lightColor} intensity={lightIntensity / 4} />
			{/* a subtle up light to see the base underside */}
			<directionalLight position={[0, -1, 0]} color={lightColor} intensity={lightIntensity / 4} />

			{/* ground plane to receive the shadow */}
			<mesh rotation-x={rotationX} receiveShadow={true} position={groundPlanePosition}>
				<planeBufferGeometry args={[500, 500]} />
				{/* opacity affects the darkness of the shadow */}
				<shadowMaterial opacity={shadowOpacity} />
			</mesh>

			{/* a down-pointing light to create a shadow on the plane shadowMaterial (intensity doesn't affect the shadow) */}

			<directionalLight
				position={shadowLightPosition}
				color={lightColor}
				intensity={0.01}
				castShadow
				shadow-mapSize-height={shadowMapSize} // change to affect the softness of the shadow (must be power of 2)
				shadow-mapSize-width={shadowMapSize}
				shadow-camera-left={-3}
				shadow-camera-right={3}
				shadow-camera-top={-3}
				shadow-camera-bottom={3}
			/>
		</>
	)
}
