// import { useState, useEffect, Suspense } from 'react'
import ReactPlayer from 'react-player/file'
import styled from 'styled-components'
// import { Canvas } from '@react-three/fiber'
// import { Html, useGLTF, OrbitControls } from '@react-three/drei'
import { FaFacebook, FaInstagram } from 'react-icons/fa'
import { TbAugmentedReality } from 'react-icons/tb'

import { CommonActionButton, CommonAButton, CommonLinkButton } from './_commonComponents'

export default function Test() {
	// console.log('Test..Block')

	// const [currentProductId, setCurrentProductId] = useState(0)

	function ButtonClicked(e) {
		// console.log('button clicked')
	}

	function NavGalleryClicked(e) {
		window.open('/', 'Gallery')
	}

	// function ShowRandomClicked(e) {
	// 	setCurrentProductId(Math.floor(Math.random() * productIds.length))
	// }

	// useEffect(() => {
	// 	// console.log('Test..1', currentProductId)
	// }, [currentProductId])

	// const Model = () => {
	// 	const productId = productIds[currentProductId]
	// 	const url = `https://cfgserver.stratumbespoke.com:4101/preset/${productId}`
	// 	// console.log(url)
	// 	return (
	// 		<>
	// 			<primitive object={useGLTF(url).scene} scale={4} />
	// 		</>
	// 	)
	// };
	const Video = () => {
		return (
			<ReactPlayer
				url='/videos/AW_param_MorphToe.mp4'
				playing={true}
				controls={true}
				loop={true}
				muted={true}
				playsinline={true}
				onReady={() => true}
				width='100%'
				height='100%'
				config={{
					file: {
						attributes: {
							controlsList: 'nofullscreen',
						},
					},
				}}
			/>
		)
	}

	return (
		<FlexCol>
			{/* <ReactTooltip delayShow={100} /> */}
			<CommonActionButton
				text='GALLERY'
				onClick={NavGalleryClicked}
				linkStyleOverrides={{ color: 'red' }}
				dataTip='this is a tooltip'
			/>
			<CommonActionButton
				text={
					<>
						HELLO <TbAugmentedReality size='25px' />
						<FaFacebook size='25px' />
						<FaInstagram />
					</>
				}
				buttonStyleOverrides={{ backgroundColor: 'green', color: 'black' }}
				dataTip='this is a tooltip'
				onClick={ButtonClicked}
			/>
			<CommonLinkButton
				text={
					<>
						WORLD <FaFacebook size='25px' />
					</>
				}
				linkStyleOverrides={{ backgroundColor: 'red' }}
				to='/'
				dataTip='this is a tooltip'
			/>
			<CommonAButton
				text='LINK'
				href='/'
				target='foo'
				linkStyleOverrides={{ color: 'black' }}
				dataTip='this is a tooltip'
			/>
			<img src='/videos/stratum-logo.png' width='500px' alt='' />
			{/* <img src='/videos/circle.svg' size='100px'/> */}
			<VideoDiv>
				<Video />
				{/* <CommonActionButton text="SHOW RANDOM MODEL" onClick={ShowRandomClicked} buttonStyleOverrides={{ width: "50%" }} dataTip="this is a tooltip" /> */}
				{/* <CanvasDiv>
				<Canvas>
					<Suspense fallback={<Html><h1>LOADING</h1></Html>}>
						<Model />
						<ambientLight />
						<OrbitControls />
					</Suspense>
				</Canvas>
			</CanvasDiv> */}
			</VideoDiv>
		</FlexCol>
	)
}

const FlexCol = styled.div`
	display: flex;
	flex-direction: column;
`

const VideoDiv = styled.div`
	position: relative;
	width: 800px;
`

// const CanvasDiv = styled.div`
// height: 500px;
// width: 500px;
// background-color: white;
// z-index: 5;
// `;

// const productIds =
// 	[
// 		"AW.B01.CT",
// 		"AW.B11.CT",
// 		"AW.B21.CT",
// 		"AW.B22.CT",
// 		"AW.W01.CT",
// 		"AW.W11.CT",
// 		"AW.W21.CT",
// 		"AW.W22.CT",
// 		"AW.B01.DE",
// 		"AW.B11.DE",
// 		"AW.B21.DE",
// 		"AW.B22.DE",
// 		"AW.W01.DE",
// 		"AW.W11.DE",
// 		"AW.W21.DE",
// 		"AW.W22.DE",
// 		"AW.B01.HT",
// 		"AW.B11.HT",
// 		"AW.B21.HT",
// 		"AW.B22.HT",
// 		"AW.W01.HT",
// 		"AW.W11.HT",
// 		"AW.W21.HT",
// 		"AW.W22.HT",
// 		"AW.B01.ST",
// 		"AW.B11.ST",
// 		"AW.B21.ST",
// 		"AW.B22.ST",
// 		"AW.W01.ST",
// 		"AW.W11.ST",
// 		"AW.W21.ST",
// 		"AW.W22.ST",
// 		"AC.B01.BE",
// 		"AC.W01.BE",
// 		"AC.B01.DE",
// 		"AC.W01.DE",
// 		"AC.B01.DT",
// 		"AC.W01.DT",
// 		"AC.B01.CT",
// 		"AC.W01.CT",
// 		"AS.N01.LS",
// 		"AS.N11.LS",
// 		"AS.N21.LS",
// 		"AS.N22.LS",
// 		"AS.N01.MS",
// 		"AS.N11.MS",
// 		"AS.N21.MS",
// 		"AS.N22.MS",
// 		"AS.N01.SS",
// 		"AS.N11.SS",
// 		"AS.N21.SS",
// 		"AS.N22.SS",
// 		"AS.N01.XL",
// 		"AS.N11.XL",
// 		"AS.N21.XL",
// 		"AS.N22.XL",
// 		"AS.N21.XS",
// 		"AS.N01.XS",
// 		"AS.N11.XS",
// 		"AS.N22.XS",
// 		"AT.B01.BS",
// 		"AT.B11.BS",
// 		"AT.W01.BS",
// 		"AT.W11.BS",
// 		"AT.B01.CS",
// 		"AT.B11.CS",
// 		"AT.W01.CS",
// 		"AT.W11.CS",
// 		"AT.B01.DC",
// 		"AT.B11.DC",
// 		"AT.W01.DC",
// 		"AT.W11.DC",
// 		"AT.B01.EC",
// 		"AT.B11.EC",
// 		"AT.W01.EC",
// 		"AT.W11.EC"
// 	]
