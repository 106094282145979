import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { BsXSquare, BsClipboardData } from 'react-icons/bs'

import { isDT } from './Global'
import { paramSpecs, truncateString, price } from './_uiHelpers'
import { decodeIncomingParams, encodeOutgoingParams, saveUrl, paramsUrl, computeUrl } from './_makeUrls'
import { CommonAButton, CommonActionButton } from './_commonComponents'
import { calcModel, gltfArea } from './_gltfModel'
import { localize } from './_localization'

export default function PagePersonal() {
	const [productId, setProductId] = useState()
	const [incomingParams, setIncomingParams] = useState()
	const [enquire, setEnquire] = useState()
	const [url, setUrl] = useState()
	const [fullNames, setFullNames] = useState()
	const [friendlyNames, setFriendlyNames] = useState()
	const [abbrevNames, setAbbrevNames] = useState()
	const [newValues, setNewValues] = useState()
	const [presetValues, setPresetValues] = useState()
	const [differences, setDifferences] = useState()
	const [presetParams, setPresetParams] = useState()
	const [filterDiffs, setFilterDiffs] = useState()
	const [filterOn, setFilterOn] = useState(false)
	const [personalProductCode, setPersonalProductCode] = useState()
	const [customerId, setCustomerId] = useState('.nnnnn_CustomerName')
	const [personalizedId, setPersonalizedId] = useState()
	const [paramsText, setParamsText] = useState()
	const [paramsDownloadLink, setParamsDownloadLink] = useState()
	const [gltfRawText, setGltfRawText] = useState()
	const [gltf, setGltf] = useState()
	const [gltfText, setGltfText] = useState()
	const [gltfDownloadLink, setGltfDownloadLink] = useState()
	const [localTextureFolder, setLocalTextureFolder] = useState()
	const [blueprint, setBlueprint] = useState()

	useEffect(() => {
		if (!enquire) {
			setUrl('')
			resetPanels()
			return
		}
		// get all of the raw lines in enquire
		const blueprintForm = enquire.replaceAll('\n', ' ') // convert possible 'Enquire' format to 'blueprint' format
		const kvpairs = blueprintForm.split(' ').filter((kvpair) => kvpair.trim() !== '') // omit blank lines
		const _productId = kvpairs[0] // this is the only non-kvpair in the bunch
		let _incomingParams = {}
		kvpairs.slice(1).map((line) => (_incomingParams[line.split('=')[0]] = line.split('=')[1]))

		const decodedParams = decodeIncomingParams(_productId, _incomingParams)
		// create the url (fullNames are converted back to abbreviatedNames in the resulting url)
		setUrl(saveUrl(_productId, decodedParams))
	}, [enquire])

	useEffect(() => {
		if (!url) return
		resetPanels()
		// extract productId and incomingParams from the url
		const _url = new URL(url)
		const _productId = _url.pathname.split('/')[2]
		setProductId(_productId)
		const _incomingParams = Object.fromEntries(_url.searchParams)
		setIncomingParams(_incomingParams)

		// get the preset params for the productId
		const pUrl = paramsUrl(_productId)
		fetch(pUrl)
			.then((response) => response.text())
			.then((params) => setPresetParams(params))
			.catch((e) => window.alert("Bad data in 'paste Enquire' or 'or paste Url"))
	}, [url])

	useEffect(() => {
		if (!(productId && incomingParams)) return
		processModelInputs() // fill the columns related to the incoming abbrev names
		setPersonalProductCode(productId, incomingParams)

		// get the model in threejs form
		calcModel({ productId: productId, currentParams: incomingParamsDecodedFullQuality() }).then((g) => setGltf(g))

		// get the raw text for the gltf
		const gltfUrl = computeUrl(productId, incomingParamsDecodedFullQuality())
		fetch(gltfUrl)
			.then((response) => response.text())
			.then((gltf) => setGltfRawText(gltf))
			.catch((e) => window.alert("Bad data in 'paste Enquire' or 'or paste Url"))
		// eslint-disable-next-line
	}, [productId, incomingParams])

	useEffect(() => {
		if (!(presetParams && newValues && abbrevNames && productId)) return
		processPresets()
		formatParamsText()
		// eslint-disable-next-line
	}, [presetParams, newValues, abbrevNames, productId])

	useEffect(() => {
		if (!(fullNames && newValues)) return
		formatParamsText()
		// eslint-disable-next-line
	}, [fullNames, newValues])

	useEffect(() => {
		setPersonalizedId(personalProductCode + customerId)
	}, [personalProductCode, customerId])

	useEffect(() => {
		if (!(gltf && gltfRawText)) return
		const materialThickness = 1.8
		const density = 7
		const area = gltfArea(gltf)
		const volume = area * materialThickness
		const weight = volume * density
		const materialIdExternal = decodeIncomingParams(productId, incomingParams).MaterialIdExternal
		const _price = price(productId, area, materialIdExternal)
		const _gltfText = []
		_gltfText.push('Measurements do not account for voids:')
		_gltfText.push(`area: ${area.toFixed(2)} m2`)
		_gltfText.push(`volume: ${volume.toFixed(1)} m3`)
		_gltfText.push(`weight: ${weight.toFixed(1)} kg`)
		_gltfText.push(`price: ${_price.toFixed(0)} CHF`)
		_gltfText.push(``)
		_gltfText.push(`Glft raw text file:`)
		_gltfText.push(`gltf file size: ${(gltfRawText.length / 1e6).toFixed(1)} MB`)
		_gltfText.push(`texture files:`)

		// eslint-disable-next-line
		const textures = [...gltfRawText.matchAll('.{10}?texture?.*?png')]
		textures.map((tex) => _gltfText.push('... ' + tex))

		_gltfText.push(``)
		_gltfText.push(`blueprint: (use the Copy button below)`)
		_gltfText.push(blueprint)

		setGltfText(_gltfText.join('\n'))
	}, [gltf, gltfRawText, blueprint, productId, incomingParams])

	useEffect(() => {
		if (!gltfRawText) return
		// create the download link
		const data = new Blob([gltfRawText], { type: 'application/octet-stream' })
		const downloadLink = window.URL.createObjectURL(data)
		setGltfDownloadLink(downloadLink)
	}, [gltfRawText])

	function incomingParamsDecodedFullQuality() {
		return { ...decodeIncomingParams(productId, incomingParams), ...{ ShowWoodgrain: 1, ShowEdges: 1, ShowFullRes: 1 } }
	}

	function resetPanels(all) {
		if (all) {
			setEnquire('')
			setUrl('')
		}
		setProductId(null)
		setIncomingParams(null)
		setFullNames(null)
		setFriendlyNames(null)
		setAbbrevNames(null)
		setNewValues(null)
		setPresetValues(null)
		setDifferences(null)
		setPresetParams(null)
		setFilterDiffs(null)
		setParamsText('')
		setParamsDownloadLink(null)
		setGltfDownloadLink(null)
		setGltfRawText(null)
		setGltf(null)
		setGltfText('')
		setFilterOn(false)
		setBlueprint(null)
	}

	function handleFilterClick() {
		setFilterOn(!filterOn)
	}

	function handleTextureClick() {
		const localFolder = localTextureFolder.replaceAll('\\', '\\\\') + '\\\\'
		setGltfRawText(gltfRawText.replaceAll('../texture/', localFolder))
	}

	function processModelInputs() {
		const decodedParams = decodeIncomingParams(productId, incomingParams)

		// get param names (abbreviated, fullRhino, and friendly) and incoming values
		// keep all in the same order for side-by-side displays

		const _abbrevs = Object.keys(incomingParams)
		setAbbrevNames(_abbrevs)

		const _values = Object.values(incomingParams)
		setNewValues(_values)

		// truncate some of the really long full Rhino names
		const _fullNames = Object.keys(decodedParams)
		setFullNames(_fullNames)

		// get the friendly names from the full Param spec in _uiHelpers
		const specs = paramSpecs(productId)
		const mapping = {} // convenient object of a set of object pairs: {rhinoName: abbrev}
		specs.forEach((param) => (mapping[param.rhinoName] = param.name))
		setFriendlyNames(Object.keys(decodedParams).map((name) => mapping[name]))

		const blueprintVals = _abbrevs.map((abbrev, index) => abbrev + '=' + _values[index])
		blueprintVals.splice(0, 0, productId)
		setBlueprint(blueprintVals.join(' '))
	}

	function processPresets() {
		// presetParams are full names. Convert to abbreviations for matching to incomingParams/abbrevNames
		const presetPairs = presetParams.split('&')
		let _presetParams = {}
		presetPairs.map((param) => (_presetParams[param.split('=')[0]] = param.split('=')[1]))
		_presetParams = encodeOutgoingParams(productId, _presetParams)

		// order values to abbrevName order
		const _orderedPresetValues = abbrevNames.map((name) => _presetParams[name])
		setPresetValues(_orderedPresetValues)

		// calculate newValue differences from preset values
		let _differences = []
		let _filterDiffs = [] // create an array to filter out 0's; true if the value is non-zero, false o.w.
		const specs = paramSpecs(productId)
		for (let i = 0; i < newValues.length; i++) {
			const newval = newValues[i]
			const preval = _orderedPresetValues[i]
			const name = abbrevNames[i]
			const param = specs.find((param) => name === param.abbrev)
			let chg = 0
			if (param === undefined) {
				console.log('undefined abbreviation; skipping', name)
				chg = 'xxxxx'
			} else {
				if (param.keyvaluepairs === '-') {
					if (param.min !== '-') {
						const rng = param.max - param.min
						const diff = newval - preval
						chg = ((diff / rng) * 100).toFixed(1)
						if (chg === '0.0') chg = '0.'
					} else {
						chg = newval === preval ? '0' : '?'
					}
				} else {
					chg = newval === preval ? '0' : '100'
				}
			}
			_differences.push(chg)
			_filterDiffs.push(!(chg === '0' || chg === '0.'))
		}
		setDifferences(_differences)
		setFilterDiffs(_filterDiffs)
	}

	function formatParamsText() {
		const paramsLines = []
		for (let i = 0; i < newValues.length; i++) {
			paramsLines.push(`set: ${newValues[i].padEnd(6)} : ${fullNames[i]}`)
		}
		setParamsText(paramsLines.join('\n'))

		// create the download link
		const data = new Blob([paramsLines.join('\n')], { type: 'application/octet-stream' })
		const downloadLink = window.URL.createObjectURL(data)
		setParamsDownloadLink(downloadLink)
	}

	function filterDisp(col) {
		if (!filterOn) return col
		return col.filter((x, index) => filterDiffs[index])
	}

	function copyBlueprint() {
		navigator.clipboard.writeText(blueprint)
	}

	const narrow = { width: '70px' }
	const buttonStyles = {
		backgroundColor: 'black',
		color: 'white',
		width: '180px',
		height: '30px',
		margin: '2px 0 0 0',
		fontSize: '14px',
	}
	const scrollSize = isDT ? { width: '100%', height: '100%' } : { width: window.innerWidth, height: window.innerHeight }
	const outerSize = isDT ? { width: '100%', height: '100%' } : { width: '900px', height: '500px' }
	const lowerReportHeight = isDT ? { width: '90%', height: '200px' } : { width: '90%', height: '90px' }
	const commentaryFontSize = isDT ? { fontSize: '12px' } : { fontSize: '12px' }
	// const lowerReportWidth = isDT ? {width: '400px'} : {width: '300px'}

	return (
		<ScrollContainer style={scrollSize}>
			<OuterContainerDiv style={outerSize}>
				<TopRowTextDiv>
					<TopRowItemDiv style={{ width: '100px' }}>
						<TopRowItemTitle dataTip="Paste the blueprint or the lines from an 'Enquire' email">
							blueprint
						</TopRowItemTitle>
						<div style={{ position: 'relative', height: '100%' }}>
							<UrlText value={enquire} onChange={(e) => setEnquire(e.target.value)} spellCheck='false' />
							<CommonActionButton
								onClick={() => resetPanels(true)}
								text={<BsXSquare size='20px' />}
								buttonStyleOverrides={{ right: 0, width: '30px', height: '30px', backgroundColor: 'transparent' }}
								dataTip='Clear Data'
							></CommonActionButton>
						</div>
					</TopRowItemDiv>
					<TopRowItemDiv style={{ width: '120px' }}>
						<TopRowItemTitle>or paste Url</TopRowItemTitle>
						<div style={{ position: 'relative', height: '100%' }}>
							<UrlText
								type='text'
								value={url}
								disabled={enquire?.length > 0}
								spellCheck='false'
								onChange={(e) => setUrl(e.target.value)}
								style={{ padding: 0 }}
							/>
							{true && (
								<CommonAButton
									text={url ? 'View in Designer' : '...'}
									href={url}
									target='_blank'
									linkStyleOverrides={buttonStyles}
								/>
							)}
						</div>
					</TopRowItemDiv>
					<TopRowItemDiv style={narrow}>
						<TopRowItemTitle>Abbrev</TopRowItemTitle>
						{abbrevNames && <ReportingText value={filterDisp(abbrevNames).join('\n')} readOnly spellCheck='false' />}
					</TopRowItemDiv>
					<TopRowItemDiv style={narrow}>
						<TopRowItemTitle>Preset</TopRowItemTitle>
						{presetValues && <ReportingText value={filterDisp(presetValues).join('\n')} readOnly />}
					</TopRowItemDiv>
					<TopRowItemDiv style={narrow}>
						<TopRowItemTitle>Value</TopRowItemTitle>
						{newValues && <ReportingText value={filterDisp(newValues).join('\n')} readOnly />}
					</TopRowItemDiv>
					<TopRowItemDiv style={narrow}>
						<TopRowItemTitle>Chg %</TopRowItemTitle>
						{differences && <ReportingText value={filterDisp(differences).join('\n')} readOnly />}
						{differences && (
							<LinkButtonDiv>
								<label>
									<input type='checkbox' value={filterOn} onClick={() => handleFilterClick()} />
									Filter
								</label>
							</LinkButtonDiv>
						)}
					</TopRowItemDiv>
					<TopRowItemDiv style={{ width: '140px' }}>
						<TopRowItemTitle>Full</TopRowItemTitle>
						{fullNames && (
							<ReportingText
								value={filterDisp(fullNames)
									.map((name) => truncateString(name, 13))
									.join('\n')}
								readOnly
								spellCheck='false'
							/>
						)}
					</TopRowItemDiv>
					<TopRowItemDiv style={{ width: '10%' }}>
						<TopRowItemTitle>Friendly</TopRowItemTitle>
						{friendlyNames && (
							<ReportingText value={(filterDisp(friendlyNames).map((name) => localize(name, 'en'))).join('\n')} readOnly spellCheck='false' />
						)}
					</TopRowItemDiv>
				</TopRowTextDiv>
				<hr style={{ borderTopStyle: 'solid', width: '80%' }} />
				<BottomRowDiv>
					<ProductCodesDiv style={{ alignSelf: 'center' }}>
						<label style={{ ...{ textAlign: 'right', margin: '6px 0 0 0' }, ...commentaryFontSize }}>
							{' '}
							filenames: (include serial # and/or customer id) &nbsp;
						</label>
						<IdText
							value={personalProductCode}
							readOnly
							style={{ width: '75px', textAlign: 'right', background: 'transparent' }}
							disabled
							spellCheck='false'
						/>
						<IdText
							value={customerId}
							onChange={(e) => setCustomerId(e.target.value)}
							spellCheck='false'
							style={{ width: '300px', textAlign: 'left' }}
						/>
					</ProductCodesDiv>
					<BottomPanelsContainerDiv style={{ width: '100%' }}>
						<BottomPanelDiv>
							<textarea
								value={paramsText}
								style={{ ...{ background: 'transparent' }, ...lowerReportHeight }}
								readOnly
								disabled
								spellCheck='false'
							></textarea>
							<IdText
								value={personalizedId + '.params'}
								readOnly
								style={{ background: 'transparent' }}
								disabled
								spellCheck='false'
							/>
							{true && (
								<CommonAButton
									text={paramsDownloadLink ? 'Download .params' : '...'}
									href={paramsDownloadLink}
									download={personalizedId + '.params'}
									linkStyleOverrides={buttonStyles}
									dataTip='look in your downloads folder'
								/>
							)}
						</BottomPanelDiv>
						<BottomPanelDiv>
							<label style={commentaryFontSize}>
								Enter your local file folder that contains texture files (e.g., birch.png)
							</label>
							<IdText
								value={localTextureFolder}
								onChange={(e) => setLocalTextureFolder(e.target.value)}
								style={{ ...{ height: '50px' } }}
								spellCheck='false'
							/>
							{true && (
								<CommonActionButton
									text={localTextureFolder && gltfRawText ? 'Change in gltf' : '...'}
									onClick={() => handleTextureClick()}
									buttonStyleOverrides={buttonStyles}
									dataTip='after clicking, note the changes in the panel to the right'
								/>
							)}
							<label style={commentaryFontSize}>
								After downloading the .gltf, use 3DBuilder to embed the texture files.
							</label>
						</BottomPanelDiv>
						<BottomPanelDiv>
							<textarea
								value={gltfText}
								style={{ ...{ background: 'transparent' }, ...lowerReportHeight }}
								readOnly
								disabled
								spellCheck='false'
							></textarea>
							<IdText
								value={personalizedId + '.gltf'}
								style={{ background: 'transparent' }}
								disabled
								spellCheck='false'
							/>
							<div style={{ display: 'flex', justifyContent: 'center' }}>
								{true && (
									<CommonAButton
										text={gltfRawText ? 'Download .gltf' : '...'}
										href={gltfDownloadLink}
										download={personalizedId + '.gltf'}
										linkStyleOverrides={buttonStyles}
										dataTip='look in your downloads folder'
									/>
								)}
								<CommonActionButton
									onClick={() => copyBlueprint()}
									text={<BsClipboardData size='20px' />}
									buttonStyleOverrides={{ right: 0, width: '30px', height: '30px', backgroundColor: 'transparent' }}
									dataTip='Copy Blueprint to clipboard'
								></CommonActionButton>
							</div>
						</BottomPanelDiv>
					</BottomPanelsContainerDiv>
				</BottomRowDiv>
			</OuterContainerDiv>
		</ScrollContainer>
	)
}

const ScrollContainer = styled.div`
	width: 100%;
	height: 100%;
	overflow: auto;
	background-color: red;
`

const OuterContainerDiv = styled.div`
	display: flex;
	flex-direction: column;
	background-color: palegreen;
`

const TopRowTextDiv = styled.div`
	display: flex;
	height: 52%;
	justify-content: center;
	margin: 0 0 3% 0;
`

const BottomRowDiv = styled.div`
	display: flex;
	flex-direction: column;
	height: 45%;
`

const TopRowItemDiv = styled.div`
	display: flex;
	flex-direction: column;
	max-width: 20%;
	margin: 0 10px 0 10px;
`

const TopRowItemTitle = styled.p`
	margin: 0;
	text-align: center;
`

const UrlText = styled.textarea`
	width: 100%;
	height: 100%;
`

const ReportingText = styled.textarea`
	height: 100%;
	background: transparent;
	text-align: left;
`

const LinkButtonDiv = styled.div`
	position: relative;
	height: 0;
	margin: 0;
	text-align: center;
`

const ProductCodesDiv = styled.div`
	display: flex;
	justify-content: flex-start;
	margin: 0 0 20px 0;
`

const IdText = styled.textarea`
	width: 90%;
	height: 20px;
	text-align: center;
	margin: 4px 0 0 0;
`

const BottomPanelsContainerDiv = styled.div`
	width: 100%;
	display: flex;
	justify-content: space-evenly;
`

const BottomPanelDiv = styled.div`
	width: 30%;
	display: flex;
	flex-direction: column;
	justify-content: start;
	align-items: center;
`
