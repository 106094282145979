import React from 'react'
import { Link } from 'react-router-dom'
import Radium from 'radium' // radium makes 'hover' work with inline styles
import QRCode from 'qrcode.react'
import styled from 'styled-components'
import { StyleRoot } from 'radium'

import { themify, colorButton0, colorButton0Text, H1 } from './Global'
import ModalBasic from './ModalBasic'
import { localize } from './_localization'

const ButtonStyle = {
	width: '255px',
	height: '45px',
	margin: 0,
	borderWidth: '0',
	color: `${colorButton0Text}`,
	borderRadius: `4px`,
	backgroundColor: `${colorButton0}`,
	':hover': {
		backgroundColor: '#555',
		color: 'white',
	},
}

const ButtonStyleMini = {
	...ButtonStyle,
	height: '20px',
}

const LinkWrapperStyle = {
	...ButtonStyle,
	':hover': {
		backgroundColor: '#555',
		color: 'white', // doesn't work
		// textDecoration: "underline"  // doesn't look good
	},
}
const LinkStyle = {
	...ButtonStyle,
	display: 'flex',
	justifyContent: 'center',
	textDecoration: 'none',
	':hover': {
		backgroundColor: '#555',
		color: 'white', // doesn't work
		// textDecoration: "underline"  // doesn't look good
	},
}

const ButtonText = {
	margin: '0',
	fontFamily: 'Sharp Sans No1 Bold',
	fontStyle: 'normal',
	// fontWeight: "800",
	fontSize: '18px',
	letterSpacing: '.05em',
	// textTransform: 'capitalize',  // doesn't work here, although 'lowercase' does
}

const ButtonTextMini = {
	...ButtonText,
	fontFamily: 'Sharp Sans No1 Medium',
	fontSize: '14px',
}

const LinkText = {
	...ButtonText,
	alignSelf: 'center',
}

class CommonActionButton extends React.Component {
	render() {
		// console.log(ButtonStyle, themify(ButtonStyle, 'ButtonStyle'))
		return (
			<button id={this?.props?.id} onClick={this?.props?.onClick} style={{ ...themify(ButtonStyle, 'ButtonStyle'), ...this?.props?.buttonStyleOverrides }}>
				<span style={themify(ButtonText, 'ButtonText')} data-tip={this?.props?.dataTip}>
					{this?.props?.text}
				</span>
			</button>
		)
	}
}
CommonActionButton = Radium(CommonActionButton)

class CommonActionButtonMini extends React.Component {
	render() {
		// console.log(this?.props?)
		return (
			<button id={this?.props?.id} onClick={this?.props?.onClick} style={{ ...themify(ButtonStyleMini, 'ButtonStyleMini'), ...this?.props?.buttonStyleOverrides }}>
				<span style={themify(ButtonTextMini, 'ButtonTextMini')} data-tip={this?.props?.dataTip}>
					{this?.props?.text}
				</span>
			</button>
		)
	}
}
CommonActionButtonMini = Radium(CommonActionButtonMini)

class CommonLinkButton extends React.Component {
	render() {
		// console.log(this?.props?)
		// for Links only, to get hover to work, must duplicate some styles in radium's StyleRoot (and then remove some properties on the overlay)
		return (
			<StyleRoot style={{ ...themify(LinkWrapperStyle, 'LinkWrapperStyle'), ...this?.props?.buttonStyleOverrides }} data-tip={this?.props?.dataTip}>
				<Link to={this?.props?.to} target={this?.props?.target} style={{ ...themify(LinkStyle, 'LinkStyle'), ...this?.props?.linkStyleOverrides }}>
					<div style={themify(LinkText, 'LinkText')}>{this?.props?.text}</div>
				</Link>
			</StyleRoot>
		)
	}
}
CommonLinkButton = Radium(CommonLinkButton)

class CommonAButton extends React.Component {
	render() {
		// console.log('AButton', this?.props?.language)
		return (
			<StyleRoot style={{ /* ...LinkWrapperStyle,  */ ...this?.props?.buttonStyleOverrides }} data-tip={this?.props?.dataTip}>
				<a href={this?.props?.href} target={this?.props?.target} download={this?.props?.download} style={{ ...themify(LinkStyle, 'LinkStyle'), ...this?.props?.linkStyleOverrides }}>
					<p style={themify(LinkText, 'LinkText')}>{this?.props?.text}</p>
				</a>
			</StyleRoot>
		)
	}
}
CommonAButton = Radium(CommonAButton)

const ArMobileLinkButton = ({ text, buttonClicked, buttonStyleOverrides, language }) => {
	return (
		<CommonActionButton
			text={text}
			onClick={buttonClicked}
			buttonStyleOverrides={buttonStyleOverrides}
			dataTip={localize(
				'Get a QR code to view on you mobile device|Obtenez un code QR à afficher sur votre appareil mobile|Holen Sie sich einen QR-Code, um ihn auf Ihrem Mobilgerät anzuzeigen',
				language
			)}
		/>
	)
}

const ArMobileLinkModal = ({ isOpen, setIsOpen, qrUrl, language }) => {
	return (
		<ModalBasic isOpen={isOpen} onClick={() => setIsOpen(false)}>
			<QrModalDiv>
				<TopHeader>
					{localize(
						'Scan On Your Mobile|Numérisez sur votre mobile|Scannen Sie auf Ihrem Handy',
						language
					)}
				</TopHeader>
				<HorizontalDivider> </HorizontalDivider>
				<BottomText>
					{localize(
						'Point your mobile device camera at the QR code below to see this product come to life in your space|Dirigez la caméra de votre appareil mobile vers le code QR ci-dessous pour voir ce produit prendre vie dans votre espace|Richten Sie die Kamera Ihres Mobilgeräts auf den QR-Code unten, um zu sehen, wie dieses Produkt in Ihrem Raum zum Leben erweckt wird',
						language
					)}
				</BottomText>
				<QRCode
					value={qrUrl}
					renderAs='svg'
					style={{
						width: '300px',
						height: '300px',
						margin: '20px',
						alignSelf: 'center',
					}}
				/>
			</QrModalDiv>
		</ModalBasic>
	)
}

export { CommonActionButton, CommonActionButtonMini, CommonLinkButton, CommonAButton, ArMobileLinkButton, ArMobileLinkModal }

const QrModalDiv = styled.div`
	width: 350px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	margin: 2px 40px;
	padding: 0 30px;
`
const TopHeader = styled(H1)`
	font-size: 22px;
	text-align: center;
`
const HorizontalDivider = styled.div`
	background-color: #d3d3d3;
	padding: 1px;
	margin: 10px;
`
const BottomText = styled.div`
	margin: 0px 10px;
`