import { useState, createContext, useReducer, useRef } from 'react'
// eslint-disable-next-line
import { useEffect } from 'react' // keep for debugging log
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import styled, { ThemeProvider } from 'styled-components'
import ReactTooltip from 'react-tooltip'
import TawkMessengerReact from '@tawk.to/tawk-messenger-react'

import { initializeEnv } from './Components/__urlsSystem'

import Global, { themeDesktop, colorBackground } from './Components/Global'
import PageConfigurator from './Components/PageConfigurator'
import PageViewer from './Components/PageViewer'
import PageArModelViewer from './Components/PageArModelViewer'
import ErrorBoundary from './Components/ErrorBoundary'
import PageSelectorGallery from './Components/PageSelectorGallery'
import PagePersonal from './Components/PagePersonal'
import PageTest from './Components/PageTest'
import { logInit } from './Components/_logging.js'

logInit()
initializeEnv()

export const GlobalStateContext = createContext({ value: null, setValue: () => {} })
export const ParamContext = createContext({ value: null, setValue: () => {} })
export const SpeedParamContext = createContext({ value: null, setValue: () => {} })

export default function App() {
	const [currentTheme, setCurrentTheme] = useState(themeDesktop) // start out with themeDesktop until we know differently

	// use useReducer to allow for sequential state updates in a single render event
	const [globalState, globalDispatch] = useReducer(reducer, {
		setCurrentTheme: setCurrentTheme,
	})

	// update the global state with a new state item (action.type:action.data is the key:value pair here)
	function reducer(state, action) {
		// console.log('reducer', state, action)
		return {
			...state,
			[action.type]: action.data,
		}
	}

	// set an individual state item value. This function is provided through GlobalStateContext
	function setGlobalStateItem(name, value) {
		globalDispatch({ type: name, data: value })
		if (name === 'language') localStorage.setItem("language", value)
	}

	const [currentParams, setCurrentParams] = useState({}) // these are set by input controls in the ParamsBlock
	const [speedParams, setSpeedParams] = useState({}) // quality/speed options

	const tawkRef = useRef()

	const tawkStyle = {
		visibility: {
			desktop: {
				xOffset: '320',
				yOffset: '5',
				position: 'br',
			},

			mobile: {
				xOffset: 3,
				yOffset: 3,
				position: 'bl',
			},
		},
	}

	const tawkLoaded = () => {
		// tawkRef.current.showWidget()
	}

	//0000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000
	//0000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000
	// retrieve language preference on first render
	useEffect(() => {
		const lang = localStorage.getItem('language')
		// console.log('App..First render', lang)
		setGlobalStateItem('language', lang === '' ? 'en' : lang)
	}, [])

	//0000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000
	//0000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000
	// empty useEffect for debugging
	// useEffect(() => {
	// 	 console.log('App..state', globalState)
	// }, [globalState]);

	//0000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000
	//0000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000
	//0000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000
	//0000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000

	return (
		<BrowserRouter>
			<GlobalStateContext.Provider value={{ globalState, setGlobalStateItem }}>
				<ThemeProvider theme={currentTheme}>
					<ParamContext.Provider value={{ currentParams, setCurrentParams }}>
						<SpeedParamContext.Provider value={{ speedParams, setSpeedParams }}>
							<Global /> {/* re-render Global every time */}
							<FullBackgroundDiv id='FullBackgroundDiv'>
								<TawkMessengerReact ref={tawkRef} customStyle={ tawkStyle } propertyId='63302f0b37898912e96b278a' widgetId='1gdq3fh9l' onLoad={tawkLoaded}/>
								<ReactTooltip delayShow={100} />
								<ErrorBoundary>
									<Routes>
										<Route path='/' element={<PageSelectorGallery />}></Route>
										<Route path='/config/:productId' element={<PageConfigurator omitBanner={false} omitAR={false} omitUpDown={true} />}></Route>
										<Route path='/viewer/:productId' element={<PageViewer omitBanner={false} omitAR={false} omitUpDown={true} />}></Route>
										<Route path='/viewerframe/:productId' element={<PageViewer omitBanner={true} omitAR={true} omitUpDown={true} />}></Route>
										<Route path='/ar/:productId' element={<PageArModelViewer />}></Route>
										<Route path='/personal' element={<PagePersonal />}></Route>
										<Route path='/test' element={<PageTest />}></Route>
									</Routes>
								</ErrorBoundary>
							</FullBackgroundDiv>
						</SpeedParamContext.Provider>
					</ParamContext.Provider>
				</ThemeProvider>
			</GlobalStateContext.Provider>
		</BrowserRouter>
	)
}

const FullBackgroundDiv = styled.div`
	position: absolute;
	top: 0;
	right: 0;
	left: 0;
	bottom: 0;
	overflow: hidden;
	background-color: ${colorBackground};
`
