import ReactGA from 'react-ga4'

// We use Google Analytics 4 (abbrev. GA or GA4) for logging user events.
// GA4 is entirely event based (different than GA Universal). Thus it works very well for code-based event logging.
// We don't rely on (but don't prohibit Google Tags).
// Events have one required parameter
// 1) 'action:' which shows in the GA console as 'Event name',
// Up to 25 additional parameters may be added, and then used in custom reports: product_id, etc.
// Should be able to configure 'product_id' as a custom dimension. Doesn't seem to work. Using 'category' instead (which shows as 'event_category' in GA reports).
const prodTracker = 'G-SKX1M57KRG'
// const betaTracker = 'G-P1HHY2QXZ8'
export function logInit() {
	ReactGA.initialize(
		prodTracker,
		// turn on debug mode to track events in the GA4 console DebugView
		{
			gaOptions: {
				debug_mode: true,
			},
		}
	)
}

export function logSession(productId) {
	ReactGA.event({
		action: 'DesignSession',
		category: productId,
	})
}

// actionName should be the name of the button: e.g, save, enquire, ar_mobile_link (AR goto button is a link, not an action; harder to capture click)
export function logAction(productId, actionName) {
	ReactGA.event({
		action: actionName,
		category: productId,
	})
}

// paramName should be the full rhinoName.
export function logParam(productId, paramName) {
	ReactGA.event({
		action: 'Param',
		category: productId,
		label: paramName,
	})
}

// paramName should be the full rhinoName.
export function logVideo(productId, paramName) {
	ReactGA.event({
		action: 'HelpVideo',
		category: productId,
		label: paramName,
	})
}

export function logArSession(productId) {
	ReactGA.event({
		action: 'ArSession',
		category: productId,
	})
}
