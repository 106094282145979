// Get urls from Amplify environment variables when in production mode (master or beta).
// Use dev hardcoded urls otherwise (so can leave set to localhost always)

//=== dev mode urls ================================================================
// const dev_designerUrl = 'https://design.stratumbespoke.com'
// const dev_designerUrl = 'https://design-beta.stratumbespoke.com'
// const dev_designerUrl = 'http://10.0.0.66:3000' // when home to test from phone
const dev_designerUrl = 'http://localhost:3000' // when away

// const dev_computeUrl = 'https://srv-middleware.stratumbespoke.com:4502'
// const dev_computeUrl = 'https://10.0.0.66:4502' // when home to test from phone
const dev_computeUrl = 'https://localhost:4502' // when away
//=== dev mode urls ================================================================

export let DESIGNER_URL
export let COMPUTE_URL

export function initializeEnv() {
	if (process.env.NODE_ENV === 'production') {
		DESIGNER_URL = process.env.REACT_APP_DESIGNER_URL
		COMPUTE_URL = process.env.REACT_APP_COMPUTE_URL
	} else {
		DESIGNER_URL = dev_designerUrl
		COMPUTE_URL = dev_computeUrl
	}
	if (process.env.NODE_ENV !== 'production') console.log(process.env, DESIGNER_URL, COMPUTE_URL, process.env.PUBLIC_URL)
}

export const stratumWebsiteUrl = 'https://stratumbespoke.com/'
export const stratumNftUrl = 'https://nft.stratumbespoke.com/'
