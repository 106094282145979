import React from 'react'
import { localize } from './_localization'
import { BsHouseFill } from 'react-icons/bs'

const ErrorComponent = () => {
	let lang = localStorage.getItem('language')
	lang = lang === '' ? 'en' : lang
	return (
		<div
			style={{
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
				height: '100%',
				justifyContent: 'center',
			}}
		>
			<h1>{localize("Something went wrong|Quelque chose s'est mal passé|Etwas ist schief gelaufen", lang)}</h1>
			<a
				href='https://stratumbespoke.com'
				style={{ background: 'black', color: 'white', padding: '10px 40px', fontSize: '30px' }}
			>
				<BsHouseFill /> STRATUM
			</a>
		</div>
	)
}

export default class ErrorBoundary extends React.Component {
	state = {
		hasError: false,
		error: { message: '', stack: '' },
		info: { componentStack: '' },
	}

	static getDerivedStateFromError = (error) => {
		return { hasError: true }
	}

	componentDidCatch = (error, info) => {
		this.setState({ error, info })
	}

	render() {
		const { hasError, error, info } = this.state
		if (hasError) console.log(error, info)
		const { children } = this.props

		return hasError ? <ErrorComponent /> : children
	}
}
