import { GlobalStateContext } from '../App'

// eslint-disable-next-line
import { useEffect, useContext, useState } from 'react'
import styled from 'styled-components'
import { BsArrowsFullscreen, BsFullscreenExit, BsInfoCircle } from 'react-icons/bs'

import { theme, isDT, isFullscreenAble, isFullscreen, enterFullScreenFunction, exitFullScreenFunction } from './Global'
// import stratumLogo from './images/stratum-logo.png'
import stratumIcon from './images/stratum-icon-black.png'
import { colorPrimaryAccent, colorPrimaryAccentContrast } from './Global'
import { stratumWebsiteUrl } from './__urlsSystem'
// eslint-disable-next-line
import { CommonAButton, CommonActionButton } from './_commonComponents'
import ConfigModal from './ModalBasic'
import { localize } from './_localization'

export default function BannerTop({ alwaysFullWidth }) {
	// console.log('Banner..Block', alwaysFullWidth ? true : false)

	const { globalState, setGlobalStateItem } = useContext(GlobalStateContext)
	const [infoModalIsOpen, setInfoModalIsOpen] = useState(false)
	const [isBannerWide, setIsBannerWide] = useState(true)

	//0000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000
	//0000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000
	// useEffect(() => {
		// console.log('Banner..1', globalState)
	// }, [])

	//0000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000
	//0000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000
	useEffect(() => {
		// console.log('Banner..2', globalState)
		setIsBannerWide(theme.BannerDiv_width_numeric > 300)
	}, [globalState])

	//0000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000
	//0000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000
	//0000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000
	//0000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000

	function LanguageParameterChanged(e) {
		setGlobalStateItem('language', e.target.value)
	}

	// enter fullScreen mode for more real estate, especially important on phones
	function goFullscreen(e) {
		if (!isFullscreen) {
			enterFullScreenFunction.apply(document.body)
		} else {
			exitFullScreenFunction.apply(document)
		}
	}

	function infoClicked() {
		setInfoModalIsOpen(true)
	}

	if (theme == null) return <></> // wait for the next render

	const buttonStylesTab = {
		margin: '0, 10px, 0, 10px',
		height: `${theme.Banner_height}`,
		backgroundColor: 'transparent',
		color: `${colorPrimaryAccentContrast}`,
		width: '',
		textAlign: 'start',
		// hover: ""
	}

	const linkStylesTab = {
		margin: 0,
		height: `${theme.Banner_height}`,
		backgroundColor: 'transparent',
		color: `${colorPrimaryAccentContrast}`,
		width: '',
	}

	const imgShiftFactorDesktop = 0.1
	const linkStylesImgTabDesktop = {
		height: `${theme.Banner_height_numeric * (1 - imgShiftFactorDesktop)}px`,
		paddingTop: `${theme.Banner_height_numeric * imgShiftFactorDesktop}px`,
		backgroundColor: 'transparent',
		width: '',
	}

	const imgShiftFactorMobile = 0.2
	const linkStylesImgTabMobile = {
		height: `${theme.Banner_height_numeric * (1 - imgShiftFactorMobile)}px`,
		paddingTop: `${theme.Banner_height_numeric * imgShiftFactorMobile}px`,
		backgroundColor: 'transparent',
		width: '',
	}

	const imgStylesDesktop = {
		height: `${theme.Banner_height_numeric * 0.9}px`,
		margin: '0 10px 0 10px',
	}

	const imgStylesMobile = {
		height: `${theme.Banner_height_numeric * 0.9}px`,
		margin: '0 10px 0 10px',
	}

	const linkStylesImgTab = isDT ? linkStylesImgTabDesktop : linkStylesImgTabMobile
	const imgStyles = isDT ? imgStylesDesktop : imgStylesMobile

	//0000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000
	//0000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000

	return (
		<BannerDiv style={{ width: alwaysFullWidth && '100%' }}>
			<BannerSectionDiv>
				{isBannerWide && (
					<CommonAButton
						href={stratumWebsiteUrl}
						target='_blank'
						text={<img src={isDT ? stratumIcon : stratumIcon} style={imgStyles} alt='' />}
						linkStyleOverrides={linkStylesImgTab}
						buttonStyleOverrides={buttonStylesTab}
						dataTip={localize(
							'Return to the main Stratum website|Retour au site Web principal de Stratum|Kehren Sie zur Hauptwebsite von Stratum zurück',
							globalState.language
						)}
					/>
				)}
				<CommonAButton
					href='/'
					target='_blank'
					text={localize('Gallery|Galerie|Galerie', globalState.language)}
					linkStyleOverrides={linkStylesTab}
					buttonStyleOverrides={buttonStylesTab}
					dataTip={localize(
						'Open the product selector gallery|Ouvrir la galerie de sélection de produits|Öffnen Sie die Produktauswahl-Galerie',
						globalState.language
					)}
				/>
			</BannerSectionDiv>
			<BannerSectionDiv>
				{isFullscreenAble && (
					<CommonActionButton
						id='fullscreenButton'
						text={
							isFullscreen ? (
								<BsFullscreenExit size={theme.IconFullscreen_size} />
							) : (
								<BsArrowsFullscreen size={theme.IconFullscreen_size} />
							)
						}
						onClick={goFullscreen}
						buttonStyleOverrides={{ ...buttonStylesTab, ...{ padding: '5px 0 0 0', ':hover': '' } }}
						dataTip={localize(
							'go to fullscreen view|passer en mode plein écran|gehe zur Vollbildansicht',
							globalState.language
						)}
					/>
				)}
			</BannerSectionDiv>
			<BannerSectionDiv>
				{isBannerWide && (
					<BannerDropdown
						onChange={LanguageParameterChanged}
						value={globalState?.language !== null ? globalState.language : 'en'}
					>
						{'English=en, Français=fr, Deutsch (Schweiz)=dk'.split(',').map((pair, index) => (
							<option key={index} value={pair.split('=')[1].trim()}>
								{pair.split('=')[0].trim()}
							</option>
						))}
					</BannerDropdown>
				)}
				<CommonActionButton
					onClick={infoClicked}
					text={<BsInfoCircle size={theme.IconFullscreen_size} />}
					buttonStyleOverrides={{ ...buttonStylesTab, margin: '3px 5px -3px 0' }}
					dataTip={localize(
						'Show system details|Afficher les détails du système|Systemdetails anzeigen',
						globalState.language
					)}
				></CommonActionButton>
				<ConfigModal isOpen={infoModalIsOpen} onClick={() => setInfoModalIsOpen(false)}>
					<WindowProps>
						{globalState?.windowProps?.map((prop, index) => (
							<p key={index}>
								{prop[0]}: {prop[1]?.toString()?.replace('\n', '')?.replace('\r', '')}
							</p>
						))}
					</WindowProps>
				</ConfigModal>
			</BannerSectionDiv>
		</BannerDiv>
	)
}

const BannerDiv = styled.div`
	width: ${(props) => props.theme.BannerDiv_width};
	display: flex;
	flex-direction: row;
	background-color: ${colorPrimaryAccent};
	justify-content: space-between;
`

const BannerSectionDiv = styled.div`
	display: flex;
	flex-direction: row;
`

const BannerDropdown = styled.select`
	margin: 0 20px 0 20px;
	padding: 4px 0 0 0;
	text-align: right;
	background: ${colorPrimaryAccent};
	color: ${colorPrimaryAccentContrast};
	border: none;
	font-family: 'Sharp Sans No1 Medium';
	font-style: normal;
	font-weight: 800;
	font-size: 12px;
	// text-transform: capitalize;
	&:focus {
		outline: none;
	}
`

const WindowProps = styled.div`
	line-height: 0;
	color: gray;
`
