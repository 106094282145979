import { GlobalStateContext, ParamContext, SpeedParamContext } from '../App'

import { useEffect, useContext, useState, useRef } from 'react'
import styled from 'styled-components'
// import { FaFacebook, FaInstagram } from 'react-icons/fa'
import { BiSend } from 'react-icons/bi'
import ReactTooltip from 'react-tooltip'
import { BsRulers /* BsPersonLinesFill, */ /* BsFileX */ } from 'react-icons/bs'

import { mobilePersonalizationBlockDiv_width_numeric } from './Global'
import BlockParams from './BlockParams'
import ConfigModal from './ModalBasic'
import { CommonActionButton } from './_commonComponents'
import { saveUrl } from './_makeUrls'
import { isModelPlacementWall, toProperCase } from './_uiHelpers'
import {
	// eslint-disable-next-line
	theme,
	isDT,
	H2,
	H3,
	H4,
	colorButton0,
	colorButton0Text,
	colorButton1,
	colorButton1Text,
	colorPersonalizationBackground,
	colorInfoContainer,
	colorProductInfoDiv,
	defaultBorderRadius,
} from './Global'
import { logAction } from './_logging'
import { localize } from './_localization'

export default function PersonalizationBlock({ uiTree, productId, incomingParams }) {
	// console.log('Pers..Block', productId, incomingParams)
	const { globalState, setGlobalStateItem } = useContext(GlobalStateContext)
	const { currentParams } = useContext(ParamContext)
	const { setSpeedParams } = useContext(SpeedParamContext)
	const initialSpeedOption = 'hq'
	const [speedOption, setSpeedOption] = useState(initialSpeedOption)

	// const [shareModalIsOpen, setShareModalIsOpen] = useState(false)
	const [saveModalIsOpen, setSaveModalIsOpen] = useState(false)
	const [leftOffsetToParentLeftEdge, setLeftOffsetToParentLeftEdge] = useState(0) // record the distance to the left edge of the screen
	const [isRoomForActionDiv_mobile, setIsRoomForActionDiv_mobile] = useState(true) // determine if there is room for the mobile ActionDiv below the UpperDiv (depending on which param group is expanded)
	const [paramsHeightChanged, setParamsHeightChanged] = useState(false) // the ParamsBlock sets this when it changes height
	const personalizationBlockDivRef = useRef()
	const upperDivRef = useRef()

	//0000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000
	//0000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000
	useEffect(() => {
		setSpeedParamContext(initialSpeedOption)
		setGlobalStateItem('showDimensions', false)
		setGlobalStateItem('showContextPerson', false)
		setGlobalStateItem('isModelPlacementWall', isModelPlacementWall(productId)) // determine placement, 'floor' or 'wall'
		// eslint-disable-next-line
	}, [])

	//0000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000
	//0000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000
	// determine the parent width to find left edge relative to this block
	useEffect(() => {
		const parentWidth = personalizationBlockDivRef.current.offsetParent.parentElement.clientWidth
		const personalizationBlockDivWidth = personalizationBlockDivRef.current.clientWidth
		setLeftOffsetToParentLeftEdge(personalizationBlockDivWidth - parentWidth) // we assume knowledge here about this block is placed in the parent
	}, [personalizationBlockDivRef.current?.offsetParent.parentElement.clientWidth])

	//0000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000
	//0000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000
	// react to a height change in the params block to see if action buttons fit below that block
	useEffect(() => {
		if (!paramsHeightChanged) return
		setParamsHeightChanged(false)

		function determineRoomForActionDiv() {
			const upperDivHeight = upperDivRef.current.clientHeight
			setIsRoomForActionDiv_mobile(window.innerHeight - upperDivHeight > 30) // this assumes UpperDiv is located at the top of the client window
		}
		setTimeout(determineRoomForActionDiv, 1000)
		// return () => clearTimeout(timer)  // this recommended cleanup seems to clear the timeout before it fires
	}, [upperDivRef.current?.clientHeight, paramsHeightChanged])

	//0000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000
	//0000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000
	// empty useEffect. uncomment for debugging
	useEffect(() => {
		// console.log('Pers..1', currentParams, speedOption)
	}, [currentParams])

	//0000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000
	//0000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000
	//0000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000
	//0000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000
	function EnquireClicked() {
		const emailTo = 'brian@stratumbespoke.com'
		const emailSub = localize(
			'I am enquiring about a custom design|Je me renseigne sur un design personnalisé|Ich erkundige mich nach einem kundenspezifischen Design',
			globalState.language
		)
		const design = productId + '%0A' + saveUrl(productId, currentParams).search.substring(1).replace(/&/g, '%0A')
		const emailBody =
			localize(
				'Hi Brian,%0A%0APlease tell me what you think about my design.%0A%0A<add any comments for Brian here>%0A%0AHere is my personalized Blueprint:%0A%0A|Bonjour Brian,%0A%0AVeuillez me dire ce que vous pensez de mon design.%0A%0A<ajoutez des commentaires pour Brian ici>%0A%0AVoici mon plan personnalisé :%0A%0A|Hallo Brian,%0A%0ABitte sag mir, was du von meinem Design hältst.%0A%0A<Füge hier Kommentare für Brian hinzu>%0A%0AHier ist mein personalisierter Bauplan:%0A%0A',
				globalState.language
			) + design
		window.open('mailto:' + emailTo + '?subject=' + emailSub + '&body=' + emailBody, '_self')
		logAction(productId, 'Enquire')
	}

	function saveClicked() {
		setSaveModalIsOpen(true)
		const url = saveUrl(productId, currentParams).toString()
		navigator.clipboard.writeText(url)
		logAction(productId, 'Save')
	}

	// function shareClicked() {
	// 	setShareModalIsOpen(true)
	// 	const url = viewerUrl(productId, currentParams).toString()
	// 	navigator.clipboard.writeText(url)
	// }

	function speedOptionChanged(speedOptionValue) {
		setSpeedOption(speedOptionValue)
		setSpeedParamContext(speedOptionValue)
	}

	const speedOptionsText = 'Highest Quality|Plus Haute Qualité|Höchste Qualität=hhq, High Quality|Haute Qualité|Haute Qualité=hq, Medium Quality|Qualité Moyenne|Mittlere Qualität=mq, Fastest|Plus Rapide|Am Schnellsten=f'
	function setSpeedParamContext(speedOptionValue) {
		let speedParams
		switch (speedOptionValue) {
			case 'hhq':
				speedParams = { ShowWoodgrain: 1, ShowEdges: 1, ShowFullRes: 1 }
				break
			case 'hq':
				speedParams = { ShowWoodgrain: 1, ShowEdges: 1, ShowFullRes: 0 }
				break
			case 'mq':
				speedParams = { ShowWoodgrain: 0, ShowEdges: 1, ShowFullRes: 0 }
				break
			case 'f':
				speedParams = { ShowWoodgrain: 0, ShowEdges: 0, ShowFullRes: 0 }
				break
			default:
		}
		setSpeedParams(speedParams)
	}

	function dimensionsClicked() {
		setGlobalStateItem('showDimensions', globalState.showDimensions ? false : true)
	}

	// function personClicked() {
	// 	setGlobalStateItem('showContextPerson', globalState.showContextPerson ? false : true)
	// }

	// eslint-disable-next-line
	const buttonWidth = '100%'
	// eslint-disable-next-line
	const buttonStylesMini = {
		backgroundColor: 'transparent',
		color: 'white',
		width: buttonWidth,
		textAlign: 'start',
	}

	// eslint-disable-next-line
	const buttonStylesIcon = {
		backgroundColor: 'transparent',
		color: 'black',
		width: '',
		height: '20px',
		margin: '-1px 0 0 0',
		padding: 0,
	}

	// The 'Action' buttons, e.g., Enquire, are placed quite differently in desktop vs mobile.
	// Desktop has vertically stacked buttons in the PersonalizationBlockDiv. Mobile uses absolute
	// positioning to spread the buttons along the bottom edge of the screen.

	// We use a similar approach for the ViewOptions, which in desktop are right below the
	// UpperDiv sliders, while in mobile these are spread along the left edge of the screen.

	// ActionDiv

	const actionDiv_large_style = {
		pointerEvents: 'all',
		margin: '10px',
	}

	const shrinkActionDivAmt = isRoomForActionDiv_mobile ? 0 : mobilePersonalizationBlockDiv_width_numeric
	const actionDiv_small_style = {
		pointerEvents: 'all',
		position: 'absolute',
		bottom: 0,
		right: shrinkActionDivAmt + 'px',
		width: window.innerWidth - shrinkActionDivAmt + 'px',
		margin: '0 0 5px 0',
		display: 'flex',
		justifyContent: 'space-evenly',
	}

	const actionButton_large_style = {
		width: '100%',
	}

	const actionButton_small_style = {
		width: '100px',
	}

	const actionDiv_style = isDT ? actionDiv_large_style : actionDiv_small_style
	const actionButton_style = isDT ? actionButton_large_style : actionButton_small_style

	// ViewOptionsDiv

	const viewDiv_large_style = {
		display: 'flex',
		justifyContent: 'space-evenly',
		margin: '0 5px 0 0',
	}

	const viewDiv_small_style = {
		pointerEvents: 'all',
		position: 'absolute',
		top: '40px',
		left: leftOffsetToParentLeftEdge + 'px',
		margin: '0 0 0 10px',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'end',
	}

	const viewButton_large_style = {
		backgroundColor: 'transparent',
		color: 'black',
		width: '',
		height: '20px',
		margin: '-1px 0 0 0',
		padding: 0,
	}

	const viewButton_small_style = {
		backgroundColor: 'transparent',
		color: 'black',
		width: '20px',
		height: '20px',
		margin: '10px 0 10px 0',
		padding: 0,
	}

	const viewDiv_style = isDT ? viewDiv_large_style : viewDiv_small_style
	const viewButton_style = isDT ? viewButton_large_style : viewButton_small_style

	//0000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000
	//0000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000
	//0000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000
	//0000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000
	return (
		<div style={{ height: '100%', pointerEvents: 'none' }}>
			{/* create an overlay on the entire screen to block ui */}
			<div
				style={{
					display: globalState.isModelLoading ? 'block' : 'none',
					position: 'absolute',
					width: '100%',
					height: '100%',
					zIndex: 2,
					pointerEvents: 'all',
				}}
			/>
			<PersonalizationBlockDiv ref={personalizationBlockDivRef}>
				{isDT && <ReactTooltip delayShow={100} />}
				<UpperDiv ref={upperDivRef}>
					<ProductInfoDiv>
						<ProductCaptionText>{localize('Based On|Basé Sur|Basiert Auf', globalState.language)}:</ProductCaptionText>
						<ModelText>
							{uiTree.name} {toProperCase(localize(uiTree.productName, globalState.language))}
						</ModelText>
					</ProductInfoDiv>
					<BlockParams
						uiTree={uiTree}
						productId={productId}
						incomingParams={incomingParams}
						setParamsHeightChanged={setParamsHeightChanged}
					/>
					<ViewOptionsDiv style={{ ...viewDiv_style }}>

						<div style={{ display: 'flex', placeContent: 'center' }}>
							<SpeedOptionHeading>{localize('View|Voir|Aussicht', globalState.language)}:</SpeedOptionHeading>
							<SpeedOptionDropdown
								onChange={(e) => {
									speedOptionChanged(e.target.value)
								}}
								value={speedOption}
							>
								{speedOptionsText.split(',').map((pair, index) => (
									<option key={index} value={pair.split('=')[1].trim()}>
										{localize(pair.split('=')[0].trim(), globalState.language)}
									</option>
								))}
							</SpeedOptionDropdown>
						</div>

						<CommonActionButton
							onClick={dimensionsClicked}
							text={<BsRulers size={theme.IconRulers_size} />}
							buttonStyleOverrides={{ ...viewButton_style, ...{ padding: '0 0 0 5px' } }}
							dataTip={localize(
								'Show/hide dimensions|Afficher/masquer les dimensions|Bemaßungen ein-/ausblenden',
								globalState.language
							)}
						></CommonActionButton>

						{/* <CommonActionButton onClick={personClicked} text={<BsPersonLinesFill size={theme.IconPerson_size} />}
							buttonStyleOverrides={{ ...buttonStylesIcon, ...{visibility: globalState.isModelPlacementWall ? 'hidden' : 'visible'} }}
							dataTip="Show/hide 170CM-high context figure">
						</CommonActionButton> */}
					</ViewOptionsDiv>
				</UpperDiv>
				<ActionDiv style={{ ...actionDiv_style }}>

					<CommonActionButton
						onClick={EnquireClicked}
						text={
							<span style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
								{localize('Enquire|Renseigner|Anfragen', globalState.language)} &nbsp;{' '}
								<div style={{ margin: '2px 0 -2px 0' }}>
									{' '}
									<BiSend size='18px' />
								</div>
							</span>
						}
						buttonStyleOverrides={{ backgroundColor: colorButton1, color: colorButton1Text, ...actionButton_style }}
						dataTip={localize(
							'Send an email to Stratum to evaluate your design|Envoyez un e-mail à Stratum pour évaluer votre conception|Senden Sie eine E-Mail an Stratum, um Ihr Design zu bewerten',
							globalState.language
						)}
					></CommonActionButton>

					<CommonActionButton
						onClick={saveClicked}
						text={
							<span style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
								{localize('Save/Share|Sauver/Partager|Speichern/Teilen', globalState.language)}
							</span>
						}
						buttonStyleOverrides={{ backgroundColor: colorButton0, color: colorButton0Text, ...actionButton_style }}
						dataTip={localize(
							'Get a URL to save your custom design|Obtenez une URL pour enregistrer votre design personnalisé|Holen Sie sich eine URL, um Ihr benutzerdefiniertes Design zu speichern',
							globalState.language
						)}
					></CommonActionButton>

					<ConfigModal isOpen={saveModalIsOpen} onClick={() => setSaveModalIsOpen(false)}>
						<p>
							{localize(
								"The URL for your current design has been copied to your clipboard. You can do one of two things.|L'URL de votre conception actuelle a été copiée dans votre presse-papiers. Vous pouvez faire l'une des deux choses.|Die URL für Ihr aktuelles Design wurde in Ihre Zwischenablage kopiert. Sie können eines von zwei Dingen tun.",
								globalState.language
							)}
						</p>
						<ol type='A'>
							<li>
								{localize(
									"Save the URL in your browser for safekeeping or alternate modifications|Enregistrez l'URL dans votre navigateur pour la conserver ou d'autres modifications|Speichern Sie die URL in Ihrem Browser zur sicheren Aufbewahrung oder für alternative Änderungen",
									globalState.language
								)}
							</li>
							<ol>
								<li>
									{localize(
										'Open a new tab in your browser|Ouvrez un nouvel onglet dans votre navigateur|Öffnen Sie einen neuen Tab in Ihrem Browser',
										globalState.language
									)}
								</li>
								<li>
									{localize(
										"Paste the URL into your browser address bar|Collez l'URL dans la barre d'adresse de votre navigateur|Fügen Sie die URL in die Adressleiste Ihres Browsers ein",
										globalState.language
									)}
								</li>
								<li>
									{localize(
										"Bookmark the tab|Ajouter l'onglet aux favoris|Setzen Sie ein Lesezeichen auf die Registerkarte",
										globalState.language
									)}
								</li>
								<li>
									{localize(
										'The bookmark is now ready for future use|Le signet est maintenant prêt pour une utilisation future|Das Lesezeichen ist jetzt bereit für die zukünftige Verwendung',
										globalState.language
									)}
								</li>
							</ol>
							<p></p>
							<li>
								{localize(
									"Send it to someone else|Envoyez-le à quelqu'un d'autre|Senden Sie es an jemand anderen",
									globalState.language
								)}
							</li>
							<ol>
								<li>
									{localize(
										"Paste the URL into an email or messaging app|Collez l'URL dans une application de messagerie ou de messagerie|Fügen Sie die URL in eine E-Mail- oder Messaging-App ein",
										globalState.language
									)}
								</li>
								<li>
									{localize(
										"Send it. Someone else can now see exactly what you are seeing|Envoie-le. Quelqu'un d'autre peut maintenant voir exactement ce que vous voyez|Schicken Sie es. Jemand anderes kann jetzt genau das sehen, was Sie sehen",
										globalState.language
									)}
								</li>
							</ol>
						</ol>
					</ConfigModal>

				</ActionDiv>
			</PersonalizationBlockDiv>
		</div>
	)
}

const PersonalizationBlockDiv = styled.div`
	position: relative;
	bottom: 0;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	width: ${(props) => props.theme.PersonalizationBlockDiv_width};
	// background-color: ${colorPersonalizationBackground};
	user-select: none;
`

const UpperDiv = styled.div`
	// padding-top: ${defaultBorderRadius + 4}px;
	// padding-bottom: ${defaultBorderRadius +
	3}px;  // the bottom section has an adjacent double-wide divider, which makes this looks larger
	// background-color: ${colorInfoContainer};
	border-radius: ${defaultBorderRadius}px;
	margin: ${(props) => props.theme.PersonalizationBlockDiv_margin};
	pointer-events: all;
`

const ProductInfoDiv = styled.div`
	height: ${(props) => props.theme.Banner_height};
	background-color: ${colorProductInfoDiv};
`

const ModelText = styled(H2)`
	margin: ${(props) => props.theme.ModelText_margin};
	color: ${(props) => props.theme._uiTextColorInverse};
	// text-transform: capitalize;
`

const ProductCaptionText = styled(H4)`
	margin-left: 10px;
	margin-bottom: 0;
	padding-top: 4px;
	color: ${(props) => props.theme._uiTextColorInverse};
	// text-transform: capitalize;
`

const ActionDiv = styled.div``

// eslint-disable-next-line
const InfoDiv = styled.div`
	margin-top: 10px;
	display: flex;
	flex-direction: column;
	padding-top: ${defaultBorderRadius}px;
	padding-bottom: ${defaultBorderRadius}px;
	background-color: ${colorInfoContainer};
	border-radius: ${defaultBorderRadius}px;
`

const ViewOptionsDiv = styled.div``

const SpeedOptionHeading = styled(H3)`
	margin: -2px 3px 0 0;
`

const SpeedOptionDropdown = styled.select`
	width: ${(props) => props.theme.SpeedOptionDropdown_width};
	margin: 0;
	padding: 0px 0 0 0;
	border: none;
	font-family: Uniform Pro Medium, sans-serif;
	font-style: normal;
	font-size: ${(props) => props.theme.SelectorDropdownDiv_fontSize};
	&:focus {
		outline: none;
	}
`
