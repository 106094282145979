
export function localize(textAlternatives, language) {
	// console.log('localize', textAlternatives, language)
	if (!textAlternatives.includes('|')) return textAlternatives

	const alternatives = textAlternatives.split('|')
	switch (language) {
		case 'en':
			return alternatives[0]
		case 'fr':
			return alternatives[1]
		case 'dk':
			return alternatives[2]
		default:
			return alternatives[0] // shouldn't happen
	}
}
