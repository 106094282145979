import { DESIGNER_URL, COMPUTE_URL } from './__urlsSystem'
import { paramSpecs } from './_uiHelpers'

// construct various url's with productId and currentParams

export function computeUrl(productId, currentParams) {
	return makeUrl('computeid', productId, currentParams, COMPUTE_URL)
}

export function paramsUrl(productId) {
	const url = makeUrl('getparams', productId, {}, COMPUTE_URL)
	return url
}

export function viewerUrl(productId, currentParams) {
	return makeUrl('viewer', productId, encodeOutgoingParams(productId, currentParams), DESIGNER_URL)
}

export function saveUrl(productId, currentParams) {
	const url = makeUrl('config', productId, encodeOutgoingParams(productId, currentParams), DESIGNER_URL)
	return url
}

export function configPresetUrl(productId) {
	const url = makeUrl('config', productId, {}, COMPUTE_URL)
	return url.pathname
}

export function arUrl(productId, currentParams) {
	const url = makeUrl('ar', productId, encodeOutgoingParams(productId, currentParams), DESIGNER_URL)
	return url
}

export function encodeOutgoingParams(productId, currentParams) {
	const specs = paramSpecs(productId)

	const mapping = {} // convenient object of a set of object pairs: {rhinoName: abbrev}
	specs.forEach((param) => (mapping[param.rhinoName] = param.abbrev))

	const encoded = {} // an encoded version of currentParams
	Object.entries(currentParams).forEach(([key, value]) => (encoded[mappedKey(key, mapping)] = value))
	// console.log('encoded', encoded)
	return encoded
}

export function decodeIncomingParams(productId, incomingParamsEncoded) {
	const specs = paramSpecs(productId)

	const mapping = {} // convenient object of a set of object pairs: {rhinoName: abbrev}
	specs.forEach((param) => (mapping[param.abbrev] = param.rhinoName))

	const decoded = {} // a decoded version of currentParams
	Object.entries(incomingParamsEncoded).forEach(([key, value]) => (decoded[mappedKey(key, mapping)] = value))
	// console.log('decoded', decoded)
	return decoded
}

function mappedKey(key, mapping) {
	// if (mapping[key] === undefined) console.log('abbrev missing', key)
	return mapping[key] === undefined ? key : mapping[key]
}

function makeUrl(path, productId, currentParams, serverUrl) {
	const url = new URL(`/${path}/${productId}`, serverUrl)
	Object.keys(currentParams).forEach((key) => url.searchParams.append(key, currentParams[key]))
	return url
}
