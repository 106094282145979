import { GlobalStateContext, ParamContext } from '../App'

import { useContext, useEffect, useState } from 'react'
import styled from 'styled-components'
import { BsArrowDownUp, BsBadgeAr } from 'react-icons/bs'

import { isDT, isArPossible } from './Global'
import { CommonAButton, ArMobileLinkButton, ArMobileLinkModal } from './_commonComponents'
import ErrorBoundary from './ErrorBoundary'
import BannerTop from './BlockBanner'
import { arUrl } from './_makeUrls'
import { localize } from './_localization'
import { logAction } from './_logging'

export default function BlockModelPresentation({ children, omitBanner, omitAR, omitUpDown }) {
	const { globalState } = useContext(GlobalStateContext)
	const { currentParams } = useContext(ParamContext)
	// console.log('BlockModelPresentation..', globalState)

	const [qrModalIsOpen, setQrModalIsOpen] = useState(false)
	const [qrUrl, setQrUrl] = useState(false)

	useEffect(() => {
		setQrUrl(arUrl(globalState.productId, currentParams))
	}, [globalState, currentParams])

	function QRClicked() {
		setQrModalIsOpen(true)
		logAction(globalState.productId, 'ArMobileLink')
	}

	function UpDownDragIcon() {
		return (
			<UpDownDragIconDiv>
				<UpDownDragIconText>
					<BsArrowDownUp size='30px' />
				</UpDownDragIconText>
			</UpDownDragIconDiv>
		)
	}

	function ArButton() {
		const viewInYourSpaceText = isDT
			? localize('View In Your Space|Voir dans Votre Espace|Ansicht in Ihrem Raum', globalState.language) + '  '
			: ''
		const viewInYourSpaceStyle = isDT
			? { position: 'absolute', left: '10px', bottom: '12px' }
			: {
					position: 'absolute',
					left: '30px',
					top: '50%',
					width: '0px',
					backgroundColor: 'transparent',
					color: 'black',
			  }
		const viewInYourSpaceButtonStyle = isDT ? {} : { height: 0 }
		const viewInYourImgSize = isDT ? '22px' : '30px'

		return isArPossible ? (
			<CommonAButton
				text={
					<>
						{viewInYourSpaceText}
						<BsBadgeAr size={viewInYourImgSize} />
					</>
				}
				href={arUrl(globalState.productId, currentParams)}
				target='_blank'
				linkStyleOverrides={viewInYourSpaceStyle}
				buttonStyleOverrides={viewInYourSpaceButtonStyle}
				dataTip={localize(
					'Use Augmented Reality on your mobile device to place your furniture in your space|Utilisez la réalité augmentée sur votre appareil mobile pour placer vos meubles dans votre espace|Verwenden Sie Augmented Reality auf Ihrem Mobilgerät, um Ihre Möbel in Ihrem Raum zu platzieren',
					globalState.language
				)}
			/>
		) : (
			<ArMobileLinkButton
				text={
					<>
						{viewInYourSpaceText}
						<BsBadgeAr size={viewInYourImgSize} />
					</>
				}
				buttonStyleOverrides={viewInYourSpaceStyle}
				buttonClicked={QRClicked}
			/>
		)
	}
	return (
		<ErrorBoundary>
			<FullScreenContainer>
				<MainPageContainerDiv>
					{!omitBanner && <BannerTop />}
					<ContentDiv>{children}</ContentDiv>
					{!omitAR && <ArButton />}
					{!omitUpDown && !isDT && <UpDownDragIcon />}
				</MainPageContainerDiv>
				<ArMobileLinkModal
					isOpen={qrModalIsOpen}
					setIsOpen={setQrModalIsOpen}
					qrUrl={qrUrl}
					language={globalState.language}
				/>
			</FullScreenContainer>
		</ErrorBoundary>
	)
}

const FullScreenContainer = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
`

const MainPageContainerDiv = styled.div`
	position: relative;
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
`

const ContentDiv = styled.div`
	position: relative;
	height: 100%;
`

const UpDownDragIconDiv = styled.div`
	position: absolute;
	top: 40px;
	left: 5px;
`

const UpDownDragIconText = styled.p``

// eslint-disable-next-line
const WindowProps = styled.div`
	position: absolute;
	top: 20%;
	left: 5px;
	line-height: 0;
	color: gray;
`
