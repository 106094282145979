import { GlobalStateContext } from '../App'

import { useContext, useEffect } from 'react'
import styled from 'styled-components'
import {
	browserName,
	osName,
	isDesktop,
	isMobile,
	isTablet,
	isChrome,
	isEdge,
	isSafari,
	browserVersion,
} from 'react-device-detect'

// this is essentially globalState. I don't know the pro/con of doing it this way instead of inside GlobalStateContext.
// It seems like it simplifies the use of useEffect in other users of this state
export let theme // set in useEffect when globalState changes
// following are set at startup and whenever the screen size changes
export let isDT // is true if isMobile, AND if the desktop browser is very small; set in setIsDT
export let isWebKit // set in setBrowserProperties
export let windowInnerHeight
export let isFullscreenAble // fullScreen-related constants...
export let isFullscreen
export let enterFullScreenFunction // and functions
export let exitFullScreenFunction
export let isIPad
export let isInstagram
export let isArPossible

export const colorBackground = '#aaa' // '#C2DBDF'
// export const colorPrimaryAccent = '#2C6777'
// export const colorPrimaryAccentContrast = 'white'
export const colorPrimaryAccent = 'white'
export const colorPrimaryAccentContrast = 'black'
export const colorPersonalizationBackground = 'transparent'
// export const colorPersonalizationBackground = '#2C6777'
export const colorButton0 = 'white'
export const colorButton0Text = 'black'
export const colorButton1 = 'black'
export const colorButton1Text = 'white'
export const colorGroupNameBackground = 'white'
export const colorInfoPanelBackground = '#4198B0'
export const colorInfoContainer = '#4198B0'
export const colorParamText = '#444'
export const colorSliderThumb = '#777' //'#2C6777'
export const colorProductInfoDiv = '#777' //'#2C6777'

export const defaultBorderRadius = 4

export const mobilePersonalizationBlockDiv_width_numeric = 150

export default function Global() {

	const { globalState, setGlobalStateItem } = useContext(GlobalStateContext)

	useEffect(() => {
		// console.log('Global..', globalState.language) // Global is re-rendered on every App render
	})

	//0000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000
	//0000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000
	useEffect(() => {
		// console.log('Global..1', globalState)
		setBrowserProperties() // set on initial render even if we don't know our environment yet
		handleWindowSizeChange()
		window.addEventListener('resize', handleWindowSizeChange)
		return () => {
			window.removeEventListener('resize', handleWindowSizeChange)
		}
		// eslint-disable-next-line
	}, [])

	// set this on every render to handle orientation change; can't see how to make this work elegantly with theme's
	const BannerDiv_width_numeric_mobile = window.innerWidth - mobilePersonalizationBlockDiv_width_numeric
	themeMobile['BannerDiv_width'] = `${BannerDiv_width_numeric_mobile}px`
	themeMobile['BannerDiv_width_numeric'] = BannerDiv_width_numeric_mobile
	themeDesktop['BannerDiv_width_numeric'] = window.innerWidth

	useEffect(() => {
		// console.log('Global..2', globalState.language)
		theme = isDT ? themeDesktop : themeMobile
		globalState.setCurrentTheme !== undefined && globalState.setCurrentTheme(theme)
	}, [globalState])

	//0000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000
	//0000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000
	//0000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000
	//0000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000

	function handleWindowSizeChange() {
		// console.log('size or orientation change', window.innerWidth)
		setBrowserProperties()
		const winProps = []
		appendWinProps(winProps)
		setGlobalStateItem('windowProps', winProps) // for debugging
	}

	// even in isDesktop, if the desktop browser gets very narrow, set mobile mode (mostly for testing)
	function setBrowserProperties() {
		const minDtUiWidth = 900
		const minDtUiHeight = 600 // useful for testing iPhone6 with address bar taking up space
		const width = window.innerWidth
		const height = window.innerHeight
		isDT = (isDesktop || isTablet) && width > minDtUiWidth && height > minDtUiHeight
		isWebKit = isDT && (isChrome || isEdge || isSafari) // enables certain types of advanced styling, e.g., sliders, only in desktop
		isIPad = isTablet && (osName === 'Mac OS' || osName === 'IOS')
		isInstagram = browserName.includes('Instagram')
		isArPossible = !isDesktop // some tablets are AR-capable
		windowInnerHeight = window.innerHeight
		isFullscreenAble = _isFullscreenAble()
		isFullscreen = _isFullscreen()
		enterFullScreenFunction = _enterFullScreenFunction()
		exitFullScreenFunction = _exitFullScreenFunction()
	}

	// fullScreen mode varies by device, browser, and brower version. There are different ways to enter/exit
	// fullScreen, iPhone does not work at all, and iPad goes fullScreen, but fails to exit fullScreen properly.
	// The semi-official standard calls for browsers to implement a function called requestFullScreen.
	// Most do, Apple doesn't. Alternative functions use a vendor-specific prefix: webkit or moz.
	// Here is a table of capabilties as of 7/28/2022.
	// The first column, F, is requestFullScreen, the second column, W, is webkitRequestFullscreen
	//======================================
	// W F Browser, BrowserVersion, OS
	//--------------------------------------
	// Y N Safari 13, Mac OS
	// Y N Safari 14, IOS
	// N N Safari 15, IOS
	// * ? Safari 15, Mac OS (iPad) tries, but fails
	// N N Chrome 103, IOS (15)
	// Y Y Chrome 103, Android
	// Y Y Chrome 87, Windows
	// N Y Firefox 102, Windows
	// Y Y Silk (i.e., Firefox) 102, Android

	// Note: iPhone does not fullScreen, and the address bar is 50px, always lost to us

	// test all of the fullScreen funtions to see which is available, if any
	function _enterFullScreenFunction() {
		const elem = document.body
		const func = elem.requestFullScreen
			? elem.requestFullscreen
			: elem.webkitRequestFullscreen
			? elem.webkitRequestFullscreen
			: elem.mozRequestFullscreen
			? elem.mozRequestFullscreen
			: 'none'
		return func
	}

	function _exitFullScreenFunction() {
		const elem = document
		const func = elem.exitFullScreen
			? elem.exitFullscreen
			: elem.webkitExitFullscreen
			? elem.webkitExitFullscreen
			: elem.mozExitFullscreen
			? elem.mozExitFullscreen
			: 'none'
		return func
	}

	// is this device capable of fullScreen; note: iPhone (ios 15) is currently the only device in the world that specifically prohibits this
	function _isFullscreenAble() {
		return _enterFullScreenFunction() !== 'none' && _exitFullScreenFunction() !== 'none' && !isIPad
	}

	// are we currently in fullScreen mode; change the fullScreen button accordingly
	function _isFullscreen() {
		const mq = { matches: window.matchMedia('(display-mode: fullscreen)').matches }.matches
		// eslint-disable-next-line
		const screenMatch = window.innerHeight === screen.height // using screen is apparently frowned upon
		return mq || screenMatch
	}

	function appendWinProps(winProps) {
		winProps.push(['osName', osName])
		winProps.push(['browserName', browserName])
		winProps.push(['browserVersion', browserVersion])
		winProps.push(['isSafari', isSafari])
		winProps.push(['isChrome', isChrome])
		winProps.push(['isDesktop', isDesktop])
		winProps.push(['isMobile', isMobile])
		winProps.push(['isTablet', isTablet])
		winProps.push(['isiPad', isIPad])
		winProps.push(['isInstagram', isInstagram])
		winProps.push(['isWebKit', isWebKit])
		// eslint-disable-next-line
		winProps.push(['screen', `w:${screen.width}, h:${screen.height}`])
		winProps.push(['inner', `w:${window.innerWidth}, h:${window.innerHeight}`])
		winProps.push(['landscape', { matches: window.matchMedia('(orientation: landscape)').matches }.matches])
		winProps.push(['fullscreen', _isFullscreen()])
		winProps.push(['fullscreenAble', _enterFullScreenFunction()?.toString()?.substring(0, 18)])
	}

	// no return value from Global component
}

export function setFixedPageSizeMode(isFixed) {
	const overflowProperty = isFixed ? 'hidden' : 'auto'
	document.getElementById('FullBackgroundDiv').style.overflow = overflowProperty
}

// take an object of style properties and update any properties that are also in the current theme
export function themify(style, styleName) {
	if (theme === undefined) return style // happens on first render

	const newStyle = JSON.parse(JSON.stringify(style))
	const nameSeparator = '_'
	const qualifiedPrefix = styleName + nameSeparator

	let namedThemeKeys = [] // the named keys in the theme that match styleName

	// find any keys in the the theme that are qualified with styleName
	if (styleName !== undefined) {
		// matching keys
		const allKeys = Object.keys(theme)
		const matchingNamedKeys = allKeys.filter((prop) => prop.startsWith(qualifiedPrefix))
		// remove the qualification, leaving only the property name
		namedThemeKeys = matchingNamedKeys.map((prop) => prop.split(nameSeparator)[1])
	}

	// create a 'sub-theme' of just the match-named properties
	const namedProps = {}
	namedThemeKeys.forEach((key) => {
		namedProps[key] = theme[qualifiedPrefix + key]
	})

	// merge the theme and the match-named properties (also include other qualified keys, but they won't be used)
	const qualifiedTheme = { ...theme, ...namedProps }

	// find keys in newStyle that have a matching key in qualifiedTheme, then update newStyle
	Object.keys(newStyle).forEach((key) => {
		const newval = qualifiedTheme[key]
		if (newval !== undefined) newStyle[key] = newval
	})
	// console.log('themify', style, styleName, theme, namedProps, newStyle)
	return newStyle
}

//0000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000
//0000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000
//0000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000
//0000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000

const mobileBannerHeightNumeric = 30 // so we can calculate pixels

export const themeDesktop = {
	_pageBackgroundColor: '#C2DBDF',
	_uiBackgroundColor: 'white',
	_uiBackgroundColorInverse: 'black',
	_uiTextColor: 'black',
	_uiTextColorInverse: 'white',
	IconChevron_size: '30px',
	IconFullscreen_size: '20px',
	IconRulers_size: '20px',
	IconPerson_size: '25px',
	ArrowSVGDiv_margin: '0',
	Banner_height_numeric: 40,
	Banner_height: '40px',
	BannerDiv_width: '100%',
	BannerDiv_width_numeric: 1000, // reset on each render
	ButtonStyle_borderRadius: '4px',
	ButtonStyle_height: '45px',
	ButtonStyle_margin: '2px 0 2px',
	ButtonText_fontSize: '18px',
	ButtonTextMini_fontSize: '14px',
	ExpandButton_margin: '0',
	GroupContainerDiv_margin: '5px 0 5px 0',
	GroupControlDiv_borderRadius: '3px',
	GroupControlDiv_flexDirection: 'row',
	GroupControlDiv_padding: '3px 12px 3px 30px',
	GroupParamsContainerDiv_padding: '10px 0 5px 0',
	H1_fontSize: '26px',
	H2_fontSize: '20px',
	H3_fontSize: '16px',
	H4_fontSize: '12px',
	H5_fontSize: '10px',
	H1_lineHeight: 'normal',
	H2_lineHeight: 'normal',
	H3_lineHeight: 'normal',
	H4_lineHeight: 'normal',
	InputDiv_width: '75%',
	MainContainerDiv_width: '100%',
	MobileSwitchContainer_display: 'unset',
	MobileVerticalControlBackground_width: '',
	ModelText_margin: '-4px 0 0 10px',
	ParamContainerDiv_justifyContent: 'space-between',
	ParamContainerDiv_margin: '0 5px -10px 5px',
	ParamIconContainerDiv_width: '30px',
	ParamIconContainerDiv_margin: '-5px 0 0 0',
	ParamResetButton_margin: '0 0 5px 10px',
	ParamSlider_height: '2px',
	ParamSlider_margin: '5px 0 0 0',
	ParamSlider_width: '100%',
	ParamTextIconContainerDiv_justifyContent: 'space-between',
	ParamTextIconContainerDiv_width: '',
	PersonalizationBlockDiv_margin: '10px',
	PersonalizationBlockDiv_width: '320px',
	PersonalizationBlockDiv_width_numeric: 320,
	PersonalizationDiv_top: '0',
	SelectorDropdownDiv_fontSize: '12px',
	SelectorDropdownDiv_margin: '10px 0 0 0',
	SliderAndValueContainer_alignItems: 'end',
	SliderAndValueContainer_position: 'unset',
	SliderLabelTopRow_justifyContent: 'space-between',
	SliderLabelTopRow_margin: '0 0 0 0',
	SliderValueText_margin: '0',
	SpeedOptionDropdown_width: '',
	VideoDiv_height: '450px',
	VideoDiv_width: '800px',
	VideoTitle_top: '-48px',
}

//0000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000
//0000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000
const themeMobile = {
	_pageBackgroundColor: colorBackground, //'#C2DBDF',
	_uiBackgroundColor: 'white',
	_uiBackgroundColorInverse: 'black',
	_uiTextColor: 'black',
	_uiTextColorInverse: 'white',
	IconChevron_size: '16px',
	IconFullscreen_size: '20px',
	IconRulers_size: '18px',
	IconPerson_size: '18px',
	ArrowSVGDiv_margin: '-2px 0 -5px 0',
	Banner_height_numeric: 30,
	Banner_height: '30px',
	BannerDiv_width: `80%`, // this is redefined in theme-selection at the top useEffect
	BannerDiv_width_numeric: 1000, // reset on each render
	ButtonStyle_borderRadius: '2px 0 0 2px',
	ButtonStyle_height: '25px',
	ButtonStyle_margin: '1px 0px 1px',
	ButtonText_fontSize: '10px',
	ButtonTextMini_fontSize: '8px',
	ExpandButton_margin: '0',
	GroupContainerDiv_margin: '4px 0 4px 0',
	GroupControlDiv_borderRadius: '3px',
	GroupControlDiv_flexDirection: 'column',
	GroupControlDiv_padding: '0 0 3px 0',
	GroupParamsContainerDiv_padding: '0 0 0 0',
	H1_fontSize: '15px',
	H2_fontSize: '11px',
	H3_fontSize: '10px',
	H4_fontSize: '9px',
	H5_fontSize: '9px',
	H1_lineHeight: 'normal',
	H2_lineHeight: 'normal',
	H3_lineHeight: 'normal',
	H4_lineHeight: '10px',
	InputDiv_width: '0',
	MainContainerDiv_width: '50%',
	MobileSwitchContainer_display: 'flex',
	MobileVerticalControlBackground_width: '50%',
	ModelText_margin: '0px 0 0 10px',
	ParamContainerDiv_justifyContent: 'center',
	ParamContainerDiv_margin: '0 0px 0 0px',
	ParamIconContainerDiv_margin: '0 0 0 0',
	ParamIconContainerDiv_width: '0',
	ParamResetButton_margin: '0 0 5px 0px',
	ParamSlider_height: '2px', // '90%',
	ParamSlider_margin: '30px 0 0 0', // '-30px 0 0 10px',
	ParamSlider_width: '80%', // '2px',
	ParamTextIconContainerDiv_justifyContent: 'center',
	ParamTextIconContainerDiv_width: '100%',
	PersonalizationBlockDiv_margin: '0 0 4px 0',
	PersonalizationBlockDiv_width: `${mobilePersonalizationBlockDiv_width_numeric}px`,
	PersonalizationBlockDiv_width_numeric: mobilePersonalizationBlockDiv_width_numeric,
	PersonalizationDiv_top: `${-mobileBannerHeightNumeric}px`, // move up by bannerHeight
	SelectorDropdownDiv_fontSize: '7px',
	SelectorDropdownDiv_margin: '3px 0 0 5px',
	SliderAndValueContainer_alignItems: 'center',
	SliderAndValueContainer_position: 'absolute',
	SliderLabelTopRow_justifyContent: 'center',
	SliderLabelTopRow_margin: '4px 0 0 0',
	SliderValueText_margin: '0 0 0 0',
	SpeedOptionDropdown_width: '66%',
	VideoDiv_height: '225px',
	VideoDiv_width: '400px',
	VideoTitle_top: '-28px',
}

//0000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000
//0000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000
//0000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000
//0000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000

export const H1 = styled.p`
	margin: 0;
	font-family: 'Sharp Sans No1 Bold';
	font-style: normal;
	font-size: ${(props) => props.theme.H1_fontSize};
	letter-spacing: 0.05em;
	color: black;
`

export const H2 = styled(H1)`
	font-size: ${(props) => props.theme.H2_fontSize};
`

export const H3 = styled(H2)`
	font-size: ${(props) => props.theme.H3_fontSize};
	letter-spacing: normal;
`

export const H4 = styled(H3)`
	font-family: Uniform Pro Medium, sans-serif;
	font-size: ${(props) => props.theme.H4_fontSize};
	line-height: ${(props) => props.theme.H4_lineHeight};
`

export const H5 = styled(H4)`
	font-size: ${(props) => props.theme.H5_fontSize};
`
